.App {
  text-align: center;
  background-color: 
  #011e3c;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: lighter;
  background-color: 
  #011e3c;
  min-height: 60px;
  
}

.App-text {
  align-items: center;
  justify-content: center;
}
.App-body {
  background-color: 
  #011e3c;
  padding-left: 20px;
  padding-right: 20px;
  
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
h1 {
  font-family: lusJakartaSans-ExtraBold, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 800;
  font-size: 60;
}
.MuiTextField-root {
  margin-top: 10px;
  margin-bottom: 10px;
}